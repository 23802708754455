//
// Layout
// --------------------------------------------------

.o-pagelayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > * {
    width: 100%;
  }
}

.o-main {
  flex-grow: 1;
  position: relative;
  z-index: index($z-index, main);
  transition: all $overlay-transition-duration;
  background-color: $color-primary;

  .is-overlay-menu-open & {
    filter: brightness($overlay-content-brightness);
  }
}

.o-main__head--padded {
  padding-top: rem($topbar-height + $section-spacing);

  @include mappy-bp(sm) {
    padding-top: rem($topbar-height-sm + $section-spacing-sm);
  }

  @include mappy-bp(lg) {
    padding-top: rem($topbar-height-sm + $section-spacing-lg);
  }
}

// Sections
.o-main__section {
  overflow: hidden; // prevent overflow scroll from negative grid margins
  position: relative;
  padding-bottom: rem($section-spacing);

  @include mappy-bp(sm) {
    padding-bottom: rem($section-spacing-sm);
  }

  @include mappy-bp(lg) {
    padding-bottom: rem($section-spacing-lg);
  }
}

.o-main__section--allow-overflow {
  overflow: visible;
}

// Spacing (default = lg)
.o-main__section--with-top-spacing,
[class*="o-main__section--push-next"] + .o-main__section:not(.o-main__section--no-top-spacing),
.o-main__section:not(.o-main__section--no-top-spacing):first-child { // keep an eye on this. could be moved onto twig logic.
  padding-top: rem($section-spacing);

  @include mappy-bp(sm) {
    padding-top: rem($section-spacing-sm);
  }

  @include mappy-bp(lg) {
    padding-top: rem($section-spacing-lg);
  }

  &.o-main__section--spacing-md {
    padding-top: rem(48px);

    @include mappy-bp(sm) {
      padding-top: rem(64px);
    }
  }

  &.o-main__section--spacing-sm {
    padding-top: rem(32px);

    @include mappy-bp(sm) {
      padding-top: rem(48px);
    }
  }

  &.o-main__section--spacing-xs {
    padding-top: rem(32px);
  }
}

.o-main__section--no-bottom-spacing {
  padding-bottom: 0;
}

.o-main__section--spacing-md {
  padding-bottom: rem(48px);

  @include mappy-bp(sm) {
    padding-bottom: rem(64px);
  }
}

.o-main__section--spacing-sm {
  padding-bottom: rem(32px);

  @include mappy-bp(sm) {
    padding-bottom: rem(48px);
  }
}

.o-main__section--spacing-xs {
  padding-bottom: rem(32px);
}

.o-main__section--bg-gray {
  background-color: $color-gray-3;
}

.o-main__section--bg-primary {
  background-color: $color-primary;
}

.o-main__section--tall {
  min-height: rem(800px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

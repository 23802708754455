//
// Form elements
// --------------------------------------------------

.c-input,
.c-checkbox,
.c-radio,
.c-textarea {
  position: relative;

  &.is-disabled,
  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
    pointer-events: none;
  }
}

// Textarea
.c-textarea__field {
  @include typi('form-element');
  font-weight: 400;
  color: $form-input-color;
  width: 100%;
  min-height: rem($form-input-height);
  resize: vertical;
  border-bottom: rem(1px) solid currentColor;
  padding: 0 rem($form-padding-x) rem($form-padding-y);

  @include mappy-bp(sm) {
    min-height: rem($form-input-height-sm);
    padding-left: rem($form-padding-x-sm);
    padding-right: rem($form-padding-x-sm);
  }

  &:focus,
  .c-textarea.has-error &:focus {
    outline: none;
    color: $color-text-inverted;
  }

  &::placeholder {
    text-transform: uppercase;
    color: currentColor;
    font-weight: 400;
    opacity: 1;
  }

  .c-textarea.has-error & {
    border-color: $color-error;
  }
}

// Inputs
.c-input__field {
  @include typi('form-element');
  font-weight: 400;
  color: $form-input-color;

  width: 100%;
  min-height: rem($form-input-height);
  padding: 0 rem($form-padding-x) rem($form-padding-y);
  border-bottom: rem(1px) solid currentColor;

  @include mappy-bp(sm) {
    min-height: rem($form-input-height-sm);
    padding-left: rem($form-padding-x-sm);
    padding-right: rem($form-padding-x-sm);
  }

  &:focus,
  .c-input.has-error &:focus {
    outline: none;
    color: $color-text-inverted;
  }

  &::placeholder {
    text-transform: uppercase;
    color: currentColor;
    font-weight: 400;
    opacity: 1;
  }

  .c-input.has-error & {
    border-color: $color-error;
  }
}


// Checkboxes & radios
.c-checkbox__input,
.c-radio__input {
  @extend .u-invisible;
}

.c-checkbox__label,
.c-radio__label {
  @include typi('paragraph-sm');
  display: flex;
  align-items: center;
  cursor: pointer;

  // frame
  &::before {
    align-self: baseline;
    flex-shrink: 0;
    margin-right: rem(16px);

    content: '';
    display: block;
    cursor: pointer;
    background: transparent;
    border: rem(1px) solid $form-input-color;
    width: rem($form-checkbox-radio-size);
    height: rem($form-checkbox-radio-size);
    text-align: center;
    transition: all $transition-time-default ease-out;

    .c-checkbox.has-error &,
    .c-radio.has-error & {
      border-color: $color-error;
    }
  }

  // indicator
  &::after {
    content: '';
    display: block;
    color: $form-input-color;
    opacity: 0;
    cursor: pointer;

    position: absolute;
    left: rem($form-checkbox-radio-size / 2);
    top: rem($form-checkbox-radio-size / 2);
    transform: translate(-50%, -50%);
    background-size: contain;
    background-repeat: no-repeat;

    .c-radio__input:checked + &,
    .c-checkbox__input:checked + & {
      opacity: 1;
    }
  }
}

// radio frame
.c-radio__label::before {
  border-radius: 50%;
}

// radio indicator
.c-radio__label::after {
  width: rem($form-checkbox-radio-size / 2);
  height: rem($form-checkbox-radio-size / 2);
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Symbols' fill='#{url-friendly-color($form-input-color)}' fill-rule='evenodd'%3E%3Cg id='assets/images/svg-raw/ic-radiobutton' transform='translate(-4 -4)' fill='#{url-friendly-color($form-input-color)}'%3E%3Ccircle id='Oval' cx='12' cy='12' r='8'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

  .c-checkbox.has-error &,
  .c-radio.has-error & {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Symbols' fill='#{url-friendly-color($color-error)}' fill-rule='evenodd'%3E%3Cg id='assets/images/svg-raw/ic-radiobutton' transform='translate(-4 -4)' fill='#{url-friendly-color($color-error)}'%3E%3Ccircle id='Oval' cx='12' cy='12' r='8'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
}

// checkbox indicator
.c-checkbox__label::after {
  width: rem($form-checkbox-radio-size / 1.5);
  height: rem($form-checkbox-radio-size / 1.5);
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='#{url-friendly-color($form-input-color)}' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E%0A");

  .c-checkbox.has-error &,
  .c-radio.has-error & {
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='#{url-friendly-color($color-error)}' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E%0A");
  }
}

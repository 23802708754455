//
// Social media icons
// --------------------------------------------------

.c-social-media__icons {
  display: flex;
  justify-content: center;

  li {
    &:not(:last-of-type) {
      margin-right: rem(32px);
    }
  }

  a {
    display: block;
    color: inherit;

    svg {
      height: rem(42px);
      width: rem(42px);
    }
  }
}

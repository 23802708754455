//
// Loader
// --------------------------------------------------

.c-loader {
  display: none;
  justify-content: center;
  align-items: center;

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: index($z-index, loader);
  background-color: rgba($color-inverted, .9);

  html.is-loading & {
    display: flex;
  }

  svg {
    width: rem(64px);
    height: rem(64px);
  }
}

html.is-loading,
html.is-loading body {
  overflow: hidden;
}

//
// Richtext spacing
// --------------------------------------------------

.c-richtext {
  // Inline elements
  p,
  address {
    padding-bottom: rem(24px);
  }

  .c-intro {
    padding-bottom: rem(32px);
  }

  // Headlines
  h1,
  .c-headline--h1 {
    padding-bottom: rem(32px);
  }

  h2,
  .c-headline--h2 {
    padding-bottom: rem(32px);
  }

  .c-headline--ci {
    padding-top: rem(8px);
  }

  h3,
  .c-headline--h3 {
    padding-bottom: rem(24px);
  }

  h4,
  .c-headline--h4 {
    padding-bottom: rem(20px);
  }

  h5,
  .c-headline--h5,
  h6,
  .c-headline--h6 {
    padding-bottom: rem(8px);

    @include mappy-bp(lg) {
      padding-bottom: rem(16px);
    }
  }

  .c-headline--meta {
    padding-bottom: rem(16px);

    @include mappy-bp(sm) {
      padding-bottom: rem(16px);
    }
  }

  .c-headline--sub,
  .c-headline--sub-lg {
    margin-top: rem(-16px);
  }

  hr {
    margin-top: rem(16px);
    margin-bottom: rem(32px);

    @include mappy-bp(sm) {
      margin-top: rem(32px);
      margin-bottom: rem(72px);
    }

    @include mappy-bp(lg) {
      margin-top: rem(40px);
      margin-bottom: rem(96px);
    }
  }


  // Lists
  ul,
  ol {
    padding-bottom: rem(24px);

    li {
      margin-bottom: rem(8px);
    }
  }

  ul ol,
  ul ul,
  ol ol,
  ol ul {
    margin-top: rem(8px);
    padding-bottom: 0;
  }


  // General
  &:not(.c-richtext--keep-top-spacing) > :first-child,
  .u-no-top-spacing {
    padding-top: 0;
    margin-top: 0;
  }

  &:not(.c-richtext--keep-bottom-spacing) > :last-child,
  .u-no-bottom-spacing {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.c-richtext--lg {
  // Inline elements
  p,
  address {
    padding-bottom: rem(32px);
  }
}

@import '~swiper/dist/css/swiper.min.css';

.c-slider {
  .swiper-slide {
    height: auto;
  }
}

// Pagination
.c-slider__pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20; // > content

  opacity: 0;
  transition: all $transition-time-medium;

  .swiper-initialized & {
    opacity: 1;
  }

  .swiper-pagination {
    transform: translate(-50%, -100%);
    left: 50%;
    display: flex;
    padding-bottom: rem(24px);
  }

  .swiper-pagination-bullet {
    width: rem(12px);
    height: rem(12px);
    background-color: #fff;
    opacity: .4;
    transition: opacity $transition-time-default;
  }

  .swiper-pagination-bullet + .swiper-pagination-bullet {
    margin-left: rem(16px);
  }

  .swiper-pagination-bullet:hover,
  .swiper-pagination-bullet:focus,
  .swiper-pagination-bullet-active {
    opacity: 1;
  }
}

//
// Cookie Consent
// --------------------------------------------------

@import 'cookieconsent/build/cookieconsent.min';

.cc-window {
  font-family: inherit;
  @include typi('cookieconsent');
  opacity: .75;
  transition: all $transition-time-default;

  &:hover {
    opacity: 1;
  }
}

.cc-btn {
  transition: all $transition-time-default;

  &:hover,
  &:focus {
    background-color: $color-text-inverted !important;
    color: $color-text !important;
  }
}

.cc-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

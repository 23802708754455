//
// Quicklinks
// --------------------------------------------------

// Contact
.c-quicklinks__item {
  display: flex;
  align-items: center;

  & + & {
    margin-top: rem(20px);
  }

  .c-quicklinks--condensed & + & {
    margin-top: rem(10px);
  }
}

.c-quicklinks__icon {
  flex-shrink: 0;
  align-self: baseline;

  svg {
    height: rem(32px);
    width: rem(32px);
  }
}

.c-quicklinks__content {
  a {
    color: inherit;

    &:hover,
    &:focus {
      color: $color-link;
    }
  }

  .c-quicklinks__icon + & {
    margin-left: rem(16px);
  }

  .c-quicklinks--condensed .c-quicklinks__icon + & {
    margin-left: rem(12px);
  }
}

//
// Scaffolding
// --------------------------------------------------

// sets base font-sizing on html element
@include typi-init;

html {
  box-sizing: border-box;
  text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  color: $color-text;
  background-color: $color-primary;
  @include font-sans;
  @include font-smoothing;

  // Dark mode is not supported
  /* stylelint-disable media-feature-name-no-unknown */
  @media (prefers-color-scheme: dark) {
    color: $color-text;
    background-color: $color-primary;
  }
}

::selection {
  background: $color-primary;
  color: $color-text;
}

.u-cookieconsent-initialiser {
  display: none;
}

// Ensure elements load hidden before ScrollReveal runs
body:not(.no-reveal) [data-reveal] {
  visibility: hidden;
}

//
// Blockquote
// --------------------------------------------------

.c-blockquote {
  position: relative;
  padding-left: rem(4px + 16px);

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: rem(4px);
    background-color: $color-primary;
  }
}

.c-blockquote__cite {
  margin-top: rem(12px);
  display: block;
}

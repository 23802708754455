//
// Richtext lists
// --------------------------------------------------

.c-richtext {
  // general list
  ul,
  ol {
    @include typi('paragraph');
    list-style-position: inside;
    list-style-type: none;
    padding-left: 1.2em;

    > li {
      &::before {
        content: "■ ";
        vertical-align: text-bottom;
        text-align: left;
        width: 1.2em;
        margin-left: -1.2em;
        display: inline-block;
        opacity: .65;
      }
    }
  }

  // ordered list
  ol {
    counter-reset: item;

    li {
      &::before {
        content: counter(item) ".";
        counter-increment: item;
        vertical-align: baseline;

        color: inherit;
      }
    }
  }

  // sub ordered list
  ol li ol {
    padding-left: 2.2em;

    > li::before {
      width: 1.8em;
      margin-left: -1.8em;
    }

    li::before {
      content: counters(item, ".");
    }
  }
}

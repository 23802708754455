//
// Big teaser
// --------------------------------------------------

.c-big-teaser {
  position: relative;
}

// Image
.c-big-teaser__img-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0; // < content

  height: 100%;
  width: 100%;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10; // > image
    background-color: rgba(0, 0, 0, .38);
  }

  picture {
    top: 0; // mandatory for lazysizes' object-fit-poylfill to work correctly
    width: 100%;
    height: 100%;
  }
}

.c-big-teaser__img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
  transition: all $transition-time-long;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
    transform: scale(1.05);
  }
}


// Content
.c-big-teaser__content {
  position: relative;
  z-index: 10; // > background image
  text-align: center;
}

.c-big-teaser__content-inner {
  position: relative;
  min-height: 100vh;
  padding-top: rem($section-spacing);
  padding-bottom: rem($section-spacing);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mappy-bp(sm) {
    padding-top: rem($section-spacing-sm);
    padding-bottom: rem($section-spacing-sm);
  }

  @include mappy-bp(lg) {
    padding-top: rem($section-spacing-lg);
    padding-bottom: rem($section-spacing-lg);
  }
}

.c-big-teaser__headline {
  text-shadow: $teaser-text-shadow;
}

.c-big-teaser__intro {
  max-width: map-get($container-max-widths, sm);
  margin-left: auto;
  margin-right: auto;
}

.c-big-teaser__button {
  margin-top: rem(24px);

  @include mappy-bp(sm) {
    margin-top: rem(32px);
  }

  @include mappy-bp(lg) {
    margin-top: rem(48px);
  }
}

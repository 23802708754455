//
// Overlay
// --------------------------------------------------

.c-overlay {
  width: 100%;
  max-width: rem(375px);
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  padding-top: rem($topbar-height);
  z-index: index($z-index, overlay);

  background-color: $color-primary;
  color: #fff;

  opacity: .75;
  transform: translateX(100%) translateX(1px); // workaround for a weird bug in chrome where the menu was visible
  transition: $overlay-transition-duration;
  transition-property: opacity, transform;
  // Out: Sharp
  transition-timing-function: cubic-bezier(.4, 0, .6, 1);

  @include mappy-bp(sm) {
    padding-top: rem($topbar-height-sm);
  }

  @include mappy-bp(lg) {
    max-width: rem(435px);
  }

  &.is-open {
    // In: Deceleration
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
    opacity: 1;
    transform: none;
  }
}

.c-overlay__inner {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  display: flex;
  flex-direction: column;
}

.c-overlay__content {
  width: 100%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: rem(40px) rem(32px);

  @include mappy-bp(lg) {
    padding: rem(64px);
    padding-bottom: rem(40px);
  }
}

.c-overlay__head {
  margin-bottom: rem(30px);

  @include mappy-bp(sm) {
    margin-bottom: rem(48px);
    text-align: center;
  }
}

.c-overlay__foot {
  margin-top: rem(40px);

  @include mappy-bp(sm) {
    margin-top: rem(64px);
  }
}

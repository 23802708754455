//
// Share buttons
// --------------------------------------------------

$share-button-size: 40px;

.c-share__buttons {
  display: flex;
}

.c-share__button {
  flex-shrink: 0;
  @include button-reset;
  cursor: pointer;
  transition: all $transition-time-default;
  height: rem($share-button-size);
  width: rem($share-button-size);
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    color: #fff;
  }

  svg {
    height: 50%;
    width: 50%;
    position: relative;
  }

  & + & {
    margin-left: rem(4px);
  }
}

.c-share__button--facebook {
  background-color: #3b5998;
}

.c-share__button--pinterest {
  background-color: #bd081c;
}

.c-share__button--twitter {
  background-color: #1da1f2;
}

//
// Team member
// --------------------------------------------------

.c-team-member {
  position: relative;
}

.c-team-member__inner {
  position: relative;

  @include mappy-bp(lg) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: rem(800px);
  }

  .c-team-member--reverse & {
    @include mappy-bp(lg) {
      flex-direction: row-reverse;
    }
  }
}

// Media box
.c-team-member__media-box {
  height: rem(450px);

  @include mappy-bp(lg) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
}

// Image
.c-team-member__img-wrapper {
  display: block;
  width: 100%;
  height: 100%;

  picture {
    top: 0; // mandatory for lazysizes' object-fit-poylfill to work correctly
    width: 100%;
    height: 100%;
  }
}

.c-team-member__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all $transition-time-long;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}

// Content
.c-team-member__content {
  position: relative;
  z-index: 10; // > image

  padding-top: rem(48px);
  padding-bottom: rem(48px);

  @include mappy-bp(sm) {
    padding-top: rem(64px);
    padding-bottom: rem(64px);
  }

  @include mappy-bp(lg) {
    padding-top: rem(96px);
    padding-bottom: rem(96px);

    flex-shrink: 0;
    width: 50%;
  }
}

.c-team-member__container {
  @include mappy-bp(lg) {
    padding-left: rem(48px);
    padding-right: rem(48px);
    margin-left: 0;

    .c-team-member--reverse & {
      margin-left: auto;
      margin-right: 0;
    }
  }

  @include mappy-bp(xl) {
    padding-left: rem(80px);
    padding-right: rem(80px);
  }
}

// Buttons
.c-team-member__buttons {
  display: flex;
  margin-top: rem(24px);

  @include mappy-bp(sm) {
    margin-top: rem(32px);
  }

  @include mappy-bp(lg) {
    margin-top: rem(48px);
  }
}

.c-team-member__button {
  flex-shrink: 0;

  height: rem(40px);
  width: rem(40px);
  transition: all $transition-time-default;
  color: #fff;
  border: rem(1px) solid currentColor;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    color: $color-inverted;
    background-color: #fff;
    border-color: #fff;
  }

  @include mappy-bp(sm) {
    height: rem(60px);
    width: rem(60px);
  }

  & + & {
    margin-left: rem(16px);

    @include mappy-bp(sm) {
      margin-left: rem(20px);
    }
  }

  svg {
    height: 50%;
    width: 50%;
  }
}

//
// Cards
// --------------------------------------------------

// Default card
.c-card {
  text-align: center;
  transition: all $transition-time-default;

  .c-cards:hover & {
    opacity: .5;
  }

  .c-cards:hover &:hover,
  .c-cards:hover &:focus {
    opacity: 1;
  }
}

// Tile card
.c-card-tile {
  display: flex;
  text-align: center;
  transition: all $transition-time-long;
  color: inherit;
  min-height: rem(445px);
  position: relative;
  padding: rem(32px) rem(24px);

  justify-content: center;
  align-items: flex-end;

  .c-cards:hover & {
    opacity: .5;
  }

  .c-cards:hover &:hover,
  .c-cards:hover &:focus {
    opacity: 1;
  }

  .c-cards__col & {
    height: 100%;
  }
}

.c-card-tile__inner {
  overflow: hidden;
}

// Image
.c-card-tile__img-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;

  // Overlay
  &::after {
    content: '';
    position: absolute;
    // negative values should prevent flickering on firefox & edge
    left: -10px;
    right: -10px;
    bottom: -10px;
    top: -10px;
    background-color: rgba(0, 0, 0, .3);
    transition: all $transition-time-long;

    .c-cards:hover .c-card-tile--hover & {
      background-color: rgba(0, 0, 0, .55);
    }

    .c-card-tile--hover:hover &,
    .c-card-tile--hover:focus & {
      background-color: rgba(0, 0, 0, .8);
    }
  }
}

.c-card-tile__img-wrapper {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;

  picture {
    top: 0; // mandatory for lazysizes' object-fit-poylfill to work correctly
    width: 100%;
    height: 100%;
  }
}

.c-card-tile__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all $transition-time-long;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }

  .c-card-tile--hover:hover &,
  .c-card-tile--hover:focus & {
    transform: scale(1.2);
  }
}

// Text
.c-card-tile__text {
  position: relative;
  z-index: 10; // > image
  width: 100%;
  transition: all $transition-time-medium;
  transform: translateY(calc(var(--hide-distance) * 1px));

  .c-card-tile:hover &,
  .c-card-tile:focus & {
    transform: translateY(0);
  }
}

.c-card-tile__text-hidden {
  opacity: 0;
  transform: translateY(rem(40px));
  transition: all $transition-time-long;

  .c-card-tile:hover &,
  .c-card-tile:focus & {
    transform: translateY(0);
    opacity: 1;
  }
}


// Icon tile card
.c-card-icon-tile {
  display: block;
  text-align: center;
  transition: all $transition-time-long;
  position: relative;
  overflow: hidden;

  max-height: rem(250px);
  background-color: $color-primary;
  color: #fff;

  .c-cards__col & {
    height: 100%;
  }

  // Overlay background
  &::after {
    content: "";
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -1px;
    top: -1px;
    z-index: 5; // > content, < overlay
    opacity: 0;
    background-color: #000;
    transition: all $transition-time-medium;

    .c-cards:hover & {
      opacity: .5;
    }
  }

  .c-cards:hover &:hover::after,
  .c-cards:hover &:focus::after {
    opacity: .7;
  }

  // Aspect ratio
  &::before {
    content: "";
    display: block;
    padding-bottom: 70%;
  }
}

.c-card-icon-tile--bg-secondary {
  background-color: $color-gray-8;
  color: #fff;
}

.c-card-icon-tile__inner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.c-card-icon-tile__headline {
  .c-card-icon-tile__img-wrapper + & {
    margin-top: rem(32px);
  }
}

// Image
.c-card-icon-tile__img-wrapper {
  display: block;
  overflow: hidden;
  width: rem(80px);
  height: rem(80px);
  margin-left: auto;
  margin-right: auto;

  picture {
    top: 0; // mandatory for lazysizes' object-fit-poylfill to work correctly
    width: 100%;
    height: 100%;
  }
}

.c-card-icon-tile__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all $transition-time-long;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}

// Overlay
.c-card-icon-tile__overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 10; // > content
  width: 100%;
  height: 100%;

  padding: rem(20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: all $transition-time-medium;
  transform: translateY(100%);
  opacity: 0;

  .c-card-icon-tile:hover &,
  .c-card-icon-tile:focus & {
    transform: translateY(0);
    opacity: 1;
  }
}

// Grid
$cards-spacing: 40px;
$cards-vertical-spacing: 72px;

.c-cards__row {
  @include make-row-spacing($cards-spacing, $cards-vertical-spacing);
}

.c-cards__col {
  @include make-col-spacing($cards-spacing, $cards-vertical-spacing);
}


//
// General settings
// --------------------------------------------------

// Breakpoints
$breakpoints: (
  xs: 480px,
  sm: 768px,
  md: 1025px,
  lg: 1200px,
  xl: 1400px
);

// Container
// INVARIANT: Each container width + (2 * $container-padding) should not be larger than the corresponding breakpoint width
$container-padding: 20px;
$container-max-widths: (
  xs: 448px,
  sm: 708px,
  md: 992px,
  lg: 1056px,
  xl: 1174px,
);

// Transition timing
$transition-time-default: .2s;
$transition-time-medium: .4s;
$transition-time-long: .8s;

// z-index indexes
$z-index: pagelayout, main, footer, bottombar, overlay, topbar, loader;

// Logos
$logo-ratio-zd: 747 / 189;
$logo-ratio-zbc: 991 / 188;
$logo-ratio-ze: 747 / 188;
$logo-ratio-zts: 989 / 188;
$logo-ratio-zh: 747 / 188;
$logo-ratio-zg: 747 / 188;
$logo-ratio: $logo-ratio-zts;
$logo-shape-ratio: 24 / 24;

// Topbar
$topbar-height: 70px;
$topbar-height-sm: 90px;
$topbar-btn-size: 35px;
$bottombar-height: 50px;

// Overlay
$overlay-content-brightness: .25;
$overlay-transition-duration: .3s;

// Section spacing
$section-spacing: 80px;
$section-spacing-sm: 96px;
$section-spacing-lg: 128px;

// Figure
$figure-aspect-ratio: 10 / 16;

// Form elements
$form-input-height: 30px;
$form-input-height-sm: 30px;
/* stylelint-disable-next-line length-zero-no-unit */
$form-padding-x: 0px;
$form-padding-y: 12px;
/* stylelint-disable-next-line length-zero-no-unit */
$form-padding-x-sm: 0px;
$form-padding-y-sm: 12px;
$form-checkbox-radio-size: 24px;
$form-input-color: $color-gray-3;


// Misc
$teaser-text-shadow: 0 0 .8em rgba(0, 0, 0, .35);

//
// Gallery
// --------------------------------------------------

@import '~swiper/dist/css/swiper.min.css';

.o-main__section--type-gallery .c-gallery {
  @include mappy-bp(md) {
    margin-left: rem(-$container-padding);
    margin-right: rem(-$container-padding);
  }
}

// Controls
.c-gallery__slider-controls {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10; // > slides

  opacity: 0;
  transition: all $transition-time-medium;

  .swiper-initialized & {
    opacity: 1;
  }
}

$gallery-slider-control-ratio: 438 / 266;

.c-gallery__slider-control-prev,
.c-gallery__slider-control-next {
  position: absolute;
  top: 0;
  z-index: 10; // > content
  transform: translateY(-50%);
  display: block;
  padding: rem(16px);

  svg {
    color: #fff;
    filter: drop-shadow(0 0 rem(12px) rgba(0, 0, 0, .5));
    width: rem(20px);
    height: rem(20px * $gallery-slider-control-ratio);
  }
}

.c-gallery__slider-control-prev {
  left: 0;
}

.c-gallery__slider-control-next {
  right: 0;
}

// Pagination
.c-gallery__slider-pagination {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10; // > content

  opacity: 0;
  transition: all $transition-time-medium;

  .swiper-initialized & {
    opacity: 1;
  }

  .swiper-pagination {
    transform: translate(-50%, -100%);
    left: 50%;
    display: flex;
    padding-bottom: rem(12px);
  }

  .swiper-pagination-bullet {
    width: rem(12px);
    height: rem(12px);
    opacity: .7;
  }

  .swiper-pagination-bullet + .swiper-pagination-bullet {
    margin-left: rem(16px);
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }
}

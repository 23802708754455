//
// Menu
// --------------------------------------------------

// staggered nav mixin (called by level1 and level2 links)
@mixin generate-staggered-nav($n, $i: 1) {
  @if ($i <= $n) {
    .is-overlay-menu-open .c-menu .c-menu-level1__item:nth-child(#{$i}) {
      transition-delay: $i * .07s;
    }
    @include generate-staggered-nav($n, ($i + 1));
  }
}

.c-menu-level1__item {
  // staggered animation
  transform: translateX(1.5em);
  opacity: 0;
  transition: all .4s;
  transition-delay: 0s;

  .is-overlay-menu-open & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  & + & {
    margin-top: rem(20px);

    @include mappy-bp(h 700px) {
      margin-top: 40px;
    }
  }
}

.c-menu-level1__link {
  display: inline-block;
  @include typi('menu');
  color: inherit;
  text-transform: uppercase;
  transition: all $transition-time-default;

  &:hover,
  &:focus {
    color: inherit;
    transform: translateX(rem(-2px));
  }

  .c-menu-level1__item.is-active & {
    font-weight: 600;
  }
}

@include generate-staggered-nav(20);


.c-menu-level2__link {
  display: inline-block;
  @include typi('menu-level2');
  color: inherit;
  transition: all $transition-time-default;

  &:hover,
  &:focus {
    color: inherit;
    transform: translateX(rem(-2px));
  }

  .c-menu-level2__item.is-active & {
    font-weight: 600;
  }
}

//
// Fonts
// --------------------------------------------------

/* stylelint-disable */

@font-face {
  font-family: 'Playlist-Script';
  src: url('../../fonts/playlist-script/Playlist-Script.ttf.woff') format('woff'),
       url('../../fonts/playlist-script/Playlist-Script.ttf.svg#Playlist-Script') format('svg'),
       url('../../fonts/playlist-script/Playlist-Script.ttf.eot'),
       url('../../fonts/playlist-script/Playlist-Script.ttf.eot?#iefix') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

/* barlow-regular - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src:
    url('../../fonts/barlow-v12-latin/barlow-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../../fonts/barlow-v12-latin/barlow-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-italic - latin */
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 400;
  src:
    url('../../fonts/barlow-v12-latin/barlow-v12-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../../fonts/barlow-v12-latin/barlow-v12-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-500 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  src:
    url('../../fonts/barlow-v12-latin/barlow-v12-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../../fonts/barlow-v12-latin/barlow-v12-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-500italic - latin */
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 500;
  src:
    url('../../fonts/barlow-v12-latin/barlow-v12-latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../../fonts/barlow-v12-latin/barlow-v12-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-700 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src:
    url('../../fonts/barlow-v12-latin/barlow-v12-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../../fonts/barlow-v12-latin/barlow-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* barlow-700italic - latin */
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 700;
  src:
    url('../../fonts/barlow-v12-latin/barlow-v12-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../../fonts/barlow-v12-latin/barlow-v12-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* stylelint-enable */

//
// Login form
// --------------------------------------------------

.c-loginform {
  color: $color-text-inverted;
  background-color: #fff;
  padding: rem(24px);
}

// Form
.c-loginform__fieldset {
  & + & {
    margin-top: rem(48px);
  }
}

.c-loginform__submit {
  text-align: left;
  margin-top: rem(32px);
}

// Return states
.c-loginform__return {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: rem(32px);
  background-color: rgba(0, 0, 0, .1);
  padding: rem(16px);
}

.c-loginform__return-icon {
  flex-shrink: 0;
  color: $color-gray-3;
  background-color: $color-gray-c;
  height: rem(32px);
  width: rem(32px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  svg {
    height: 60%;
    width: 60%;
  }
}

.c-loginform__return-icon--success {
  color: #fff;
  background-color: $color-success;
}

.c-loginform__return-icon--error {
  color: #fff;
  background-color: $color-error;
}

.c-loginform__return-message {
  margin-left: rem(12px);
}

// Grid
$loginform-spacing: 20px;
$loginform-spacing-sm: 20px;
$loginform-vertical-spacing: 20px;
$loginform-vertical-spacing-sm: 20px;

.c-loginform__row {
  @include make-row-spacing($loginform-spacing, $loginform-vertical-spacing);

  @include mappy-bp(sm) {
    @include make-row-spacing($loginform-spacing-sm, $loginform-vertical-spacing-sm);
  }
}

.c-loginform__col {
  @include make-col-spacing($loginform-spacing, $loginform-vertical-spacing);

  @include mappy-bp(sm) {
    @include make-col-spacing($loginform-spacing-sm, $loginform-vertical-spacing-sm);
  }
}

//
// Footer
// --------------------------------------------------

// Grid variables
$footer-spacing: 32px;
$footer-spacing-sm: 64px;
$footer-spacing-lg: 200px;
$footer-vertical-spacing: 64px;
$footer-vertical-spacing-sm: 64px;
$footer-vertical-spacing-lg: 64px;


.c-footer {
  position: relative;
  z-index: index($z-index, footer);
  background-color: #fff;
  color: $color-text-inverted;

  transition: all $overlay-transition-duration;

  .is-overlay-menu-open & {
    filter: brightness($overlay-content-brightness);
  }
}

.c-footer__btn {
  margin-top: rem(32px);
}

.c-richtext .c-footer__headline {
  color: #fff;
}

// Beta
.c-footer__beta {
  padding-top: rem(30px);
  padding-bottom: rem(30px);
}

.c-footer__beta-inner {
  display: flex;
  align-items: center;
}

// Logo shape
.c-footer__logo-shape {
  align-self: baseline;
  margin-right: rem(16px);
  flex-shrink: 0;

  svg {
    height: rem(24px);
    width: rem(24px);
  }
}

// Menu
.c-footer__menu {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: rem(-8px);
  }

  li {
    margin: rem(8px);
  }

  a {
    display: flex;
    align-items: center;
    color: $color-gray-3;
    transition: color $transition-time-default;
    @include typi('footer');

    &:hover,
    &:focus {
      color: inherit;
    }

    svg {
      flex-shrink: 0;
      height: .5em;
      width: .5em;
      margin-right: .3em;
    }
  }
}

//
// Panels
// --------------------------------------------------

$panel-padding-x: 16px;
$panel-padding-x-sm: 24px;
$panel-icon-size: 20px;

.c-panel {
  position: relative;
  width: 100%;
  transition: all $transition-time-default;

  & + & {
    border-top: 1px solid rgba(255, 255, 255, .5);
  }
}

.c-panel__header-wrapper {
  position: relative;
  display: flex;
}

.c-panel__header {
  flex-grow: 1;

  @include button-reset;
  cursor: pointer;
  text-align: left;
  @include typi('h4');
  // text-transform: uppercase; // really?

  display: flex;
  align-items: baseline;
  width: 100%;
  padding: rem(16px) rem($panel-padding-x);
  color: $color-text-inverted;
  transition: all $transition-time-default;

  @include mappy-bp(sm) {
    padding: rem(20px) rem($panel-padding-x-sm);
  }

  &:hover,
  &:focus,
  .c-panel.is-expanded & {
    background-color: #fff;
  }
}

.c-panel__header-content {
  width: 100%;

  @include mappy-bp(sm) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.c-panel__headline {
  display: block;
  font-weight: 500;
  transition: all $transition-time-default;

  &,
  .c-panel__header:hover &,
  .c-panel__header:focus &,
  .c-panel.is-expanded & {
    color: inherit;
  }
}

.c-panel__toggle-icon {
  flex-shrink: 0;
  width: rem($panel-icon-size);
  height: rem($panel-icon-size);
  transition: all $transition-time-default;
  margin-right: rem(16px);
  transform: translateY(.05em) rotate(-90deg);

  svg {
    height: 100%;
    width: 100%;
  }

  .c-panel.is-expanded & {
    transform: translateY(.05em) rotate(0deg);
  }
}

.c-panel__panel {
  &.houdini {
    max-height: 75vh; // overwritten by JS
    transition: max-height ease-in-out $transition-time-default;
    overflow: hidden;

    .c-panel.is-collapsed & {
      max-height: 0 !important;
    }
  }
}

.c-panel__content {
  background-color: #fff;
  color: $color-text-inverted;
  padding: 0 rem($panel-padding-x + 16px) rem(16px) rem($panel-padding-x + $panel-icon-size + 16px);

  @include mappy-bp(sm) {
    padding: rem(16px) rem($panel-padding-x-sm + $panel-icon-size + 16px) rem(24px);
  }
}

//
// Map
// --------------------------------------------------

.c-map__aspect-parent {
  position: relative;
  overflow: hidden;
  padding-bottom: 75%;
  height: 0;

  @include mappy-bp(sm) {
    padding-bottom: 50%;
  }

  @include mappy-bp(md) {
    padding-bottom: 40%;
  }
}

.c-map__aspect-target {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.c-map__map {
  background-color: $color-gray-3;
  height: 100%;
  width: 100%;
}

.leaflet-container .c-map-popup {
  .leaflet-popup-content-wrapper {
    background-color: $color-inverted;
    color: $color-text;
  }

  .leaflet-popup-tip {
    background-color: $color-inverted;
  }

  .leaflet-popup-content {
    margin: rem(20px);
  }

  .leaflet-popup-content p {
    margin: 0;
  }

  a {
    color: inherit;

    &:hover,
    &:focus {
      color: $color-inverted;
    }
  }
}

//
// Richtext
// --------------------------------------------------

@import 'richtext-spacing';
@import 'richtext-list';

.c-richtext {
  // Inline elements
  p,
  address {
    @include typi('paragraph');
  }

  .u-text-sm {
    @include typi('paragraph-sm');
  }

  .u-text-lg {
    @include typi('paragraph-lg');
  }

  .u-text-xl {
    @include typi('paragraph-xl');
  }

  strong:not(.c-headline) {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  mark {
    color: $color-text-inverted;
    background-color: $color-primary;
    box-shadow: 0 0 0 .1em $color-primary;
  }

  a {
    color: $color-link;
    text-decoration: none;
    transition: color $transition-time-default;

    &:hover,
    &:focus {
      color: $color-link-hover;
    }

    .o-icon {
      display: inline;
      height: 1em;
      width: 1em;
      vertical-align: baseline;
      transform: translateY(.15em);
    }
  }

  &.c-richtext--inverted a {
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;
    }
  }

  abbr {
    text-decoration: none;
    border-bottom: rem(1px) dashed currentColor;
    cursor: help;
  }

  sub,
  sup {
    font-size: (15 / 18) * 1em;
    line-height: 0;

    a {
      text-decoration: none;
    }
  }

  sup {
    vertical-align: super;
  }

  sub {
    vertical-align: sub;
  }

  small {
    @include typi('paragraph-sm');
  }

  hr {
    background-color: currentColor;
    height: rem(1px);
    border: 0 none;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  // Headlines
  h1,
  .c-headline--h1 {
    @extend .c-headline;
    @extend .c-headline--h1;
  }

  .c-headline--h1-script {
    @extend .c-headline;
    @extend .c-headline--h1-script;
  }

  h2,
  .c-headline--h2 {
    @extend .c-headline;
    @extend .c-headline--h2;
  }

  .c-headline--h2-script {
    @extend .c-headline;
    @extend .c-headline--h2-script;
  }

  h3,
  .c-headline--h3 {
    @extend .c-headline;
    @extend .c-headline--h3;
  }

  h4,
  .c-headline--h4 {
    @extend .c-headline;
    @extend .c-headline--h4;
  }

  h5,
  .c-headline--h5 {
    @extend .c-headline;
    @extend .c-headline--h5;
  }

  h6,
  .c-headline--h6 {
    @extend .c-headline;
    @extend .c-headline--h6;
  }

  .c-headline--meta {
    @extend .c-headline;
    @extend .c-headline--meta;
  }

  .c-headline--alpha {
    @extend .c-headline;
    @extend .c-headline--alpha;
  }

  .c-headline--alpha-script {
    @extend .c-headline;
    @extend .c-headline--alpha-script;
  }

  .c-headline--sub {
    @extend .c-headline;
    @extend .c-headline--sub;
  }

  .c-headline--sub-lg {
    @extend .c-headline;
    @extend .c-headline--sub-lg;
  }

  .c-headline--ci {
    @extend .c-headline;
    @extend .c-headline--ci;
  }

  .c-headline--h1,
  .c-headline--h1-script,
  .c-headline--h2,
  .c-headline--h2-script,
  .c-headline--h3,
  .c-headline--h4,
  .c-headline--h5,
  .c-headline--h6,
  .c-headline--meta,
  .c-headline--alpha,
  .c-headline--alpha-script,
  .c-headline--sub,
  .c-headline--sub-lg {
    color: inherit;

    a {
      &,
      &:hover,
      &:focus {
        text-decoration: inherit;
        color: inherit;
      }
    }
  }

  // Headline classes on <a>
  a.c-headline--h1,
  a.c-headline--h1-script,
  a.c-headline--h2,
  a.c-headline--h2-script,
  a.c-headline--h3,
  a.c-headline--h4,
  a.c-headline--h5,
  a.c-headline--h6,
  a.c-headline--meta,
  a.c-headline--alpha,
  a.c-headline--alpha-script,
  a.c-headline--sub,
  a.c-headline--sub-lg {
    &,
    &:hover,
    &:focus {
      text-decoration: inherit;
      color: inherit;
    }
  }
}

.c-richtext--centered {
  text-align: center;
}

.c-richtext--underline-inverted a {
  text-decoration-color: #fff;
}

.c-richtext--no-underline a {
  text-decoration: none;
}

.c-richtext--sm {
  p,
  address,
  ul,
  ol,
  table {
    @include typi('paragraph-sm');
  }
}

.c-richtext--lg {
  p,
  address,
  ul,
  ol,
  table {
    @include typi('paragraph-lg');
  }
}

.c-richtext--xl {
  p,
  address,
  ul,
  ol,
  table {
    @include typi('paragraph-xl');
  }
}

.c-richtext--on-primary {
  mark {
    color: $color-primary;
    background-color: #fff;
    box-shadow: 0 0 0 .1em #fff;
  }

  a {
    color: #fff;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: #fff;
    }
  }

  &.c-richtext--no-underline a {
    text-decoration: none;
  }
}

//
// Colors
// --------------------------------------------------

// Shades of gray
$color-gray-c: #cecfd0;
$color-gray-8: #808486;
$color-gray-3: #333;
$color-gray-0: #000;

// CI colors
$color-ze: #d6d600;
$color-zts: #9bcee5;
$color-zbc: #d50021;
$color-zd: #f47d00;

// Color mapping
$color-primary: $color-zts;
$color-inverted: $color-gray-0;

$color-text: #fff;
$color-text-light: $color-gray-8;
$color-text-inverted: $color-inverted;

$color-link: $color-primary;
$color-link-hover: inherit;

$color-error: $color-zbc;
$color-success: $color-ze;


//
// Video
// --------------------------------------------------

.c-video-embed {
  iframe {
    max-width: 100%;
  }
}

.c-video-embed--aspect-ratio {
  position: relative;

  &::after {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }

  iframe {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

//
// Contact form
// --------------------------------------------------

:root {
  --envelope-offset-modifier: 1;

  @include mappy-bp(max sm) {
    --envelope-offset-modifier: .5;
  }
}

$contactform-envelope-offset: 20px;
$contactform-envelope-ar: 384 / 649;

.c-contactform {
  @include mappy-bp(md) {
    margin-left: calc(#{rem(-$contactform-envelope-offset)} * var(--envelope-offset-modifier));
    margin-right: calc(#{rem(-$contactform-envelope-offset)} * var(--envelope-offset-modifier));
  }
}

.c-contactform__inner {
  background-color: $color-gray-c;
  color: $color-gray-3;
  padding: rem(32px) rem(20px);
  position: relative;

  // Test if envelope is supported
  @supports (clip-path: polygon(0 0, 0% 100%, 100% 100%)) {
    padding-bottom: rem(8px + 2px);
    margin-left: calc(#{rem($contactform-envelope-offset)} * var(--envelope-offset-modifier));
    margin-right: calc(#{rem($contactform-envelope-offset)} * var(--envelope-offset-modifier));
  }

  @include mappy-bp(sm) {
    padding-left: rem(24px);
    padding-right: rem(24px);
    padding-top: rem(48px);
  }

  @include mappy-bp(lg) {
    padding-left: rem(48px);
    padding-right: rem(48px);
  }
}

// Loader
.c-contactform__loader {
  display: none;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;

  .c-contactform.is-loading & {
    display: flex;
  }

  svg {
    width: rem(64px);
    height: rem(64px);
  }
}

// AJAX State Messages
.c-contactform__ajax-messages .c-contactform__return--success,
.c-contactform__ajax-messages .c-contactform__return--error {
  display: none;
}

.c-contactform.has-succeeded .c-contactform__ajax-messages .c-contactform__return--success {
  display: flex;
}

.c-contactform.has-error .c-contactform__ajax-messages .c-contactform__return--error {
  display: flex;
}

// Form
.c-contactform__form {
  .c-contactform.is-loading & {
    opacity: .1;
  }

  .c-contactform.has-succeeded & {
    display: none;
  }

  .c-contactform.has-error & {
    margin-top: rem(48px);
  }
}

.c-contactform__elements {
  .c-contactform__hashtags + & {
    margin-top: rem(40px);
  }

  @include mappy-bp(sm) {
    padding-left: rem(24px);
    padding-right: rem(24px);
  }
}

.c-contactform__hashtags-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: rem(-4px);

  @include mappy-bp(sm) {
    margin: rem(-10px);
  }
}

.c-contactform__hashtag {
  margin: rem(4px);
  width: 100%;

  @include typi('hashtag');
  text-align: center;
  background-color: #fff;
  border-radius: rem(24px);
  padding: rem(12px) rem(24px);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include mappy-bp(sm) {
    margin: rem(10px);
    width: auto;
  }
}

.c-contactform__fieldset {
  & + & {
    margin-top: rem(48px);
  }
}

.c-contactform__headline {
  // <legend> resets
  width: 100%;
  display: table;

  font-weight: 400;
  text-align: center;
  margin-bottom: rem(24px);


  .c-contactform__fieldset & {
    margin-bottom: rem(32px);
  }
}

.c-contactform__submit {
  margin-top: rem(24px);

  .c-button {
    width: 100%;
  }
}

// Return states
.c-contactform__return {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: rem(400px);
  margin-left: auto;
  margin-right: auto;
}

.c-contactform__return-icon {
  color: $color-gray-3;
  background-color: $color-gray-c;
  height: rem(64px);
  width: rem(64px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  svg {
    height: 60%;
    width: 60%;
  }
}

.c-contactform__return-icon--success {
  color: #fff;
  background-color: $color-success;
}

.c-contactform__return-icon--error {
  color: #fff;
  background-color: $color-error;
}

.c-contactform__return-message {
  margin-top: rem(24px);
}

// Envelope
@supports (clip-path: polygon(0 0, 0% 100%, 100% 100%)) {
  .c-contactform__envelope {
    background-color: #767474;
    position: relative;
    padding-bottom: $contactform-envelope-ar * 100%;
    overflow: hidden;
    height: 0;
    margin-top: rem(-2px);
  }

  .c-contactform__envelope-inner {
    background-color: $color-gray-c;

    position: absolute;
    left: calc(#{rem($contactform-envelope-offset)} * var(--envelope-offset-modifier));
    right: calc(#{rem($contactform-envelope-offset)} * var(--envelope-offset-modifier));
    top: 0;
    bottom: 0;

    // 2px offsets = 1px bug workarounds
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: rem(-2px);
      left: calc(#{rem(-$contactform-envelope-offset)} * var(--envelope-offset-modifier) - 2px);
      right: calc(#{rem(-$contactform-envelope-offset)} * var(--envelope-offset-modifier) - 2px);
    }

    &::before {
      z-index: 10;
      background-color: #333;
      clip-path: polygon(0 0, 0% 100%, 100% 100%);
    }

    &::after {
      z-index: 5;
      background-color: #464444;
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    }
  }
}

// Grid
$contactform-spacing: 20px;
$contactform-spacing-sm: 20px;
$contactform-vertical-spacing: 32px;
$contactform-vertical-spacing-sm: 32px;

.c-contactform__row {
  @include make-row-spacing($contactform-spacing, $contactform-vertical-spacing);

  @include mappy-bp(sm) {
    @include make-row-spacing($contactform-spacing-sm, $contactform-vertical-spacing-sm);
  }
}

.c-contactform__col {
  @include make-col-spacing($contactform-spacing, $contactform-vertical-spacing);

  @include mappy-bp(sm) {
    @include make-col-spacing($contactform-spacing-sm, $contactform-vertical-spacing-sm);
  }
}


//
// FAQ
// --------------------------------------------------


// Overview
.c-faq-overview {
  text-align: center;
}

.c-faq-overview__headline {
  margin-bottom: rem(6px);
}

.c-faq-overview__subline,
.c-faq-overview__search,
.c-faq-overview__faqs-text {
  color: $color-text-inverted;
}

.c-faq-overview__subline {
  margin-bottom: rem(48px);
  font-weight: 500;
}

.c-faq-overview__faqs-text {
  margin-bottom: rem(20px);
}

// Grid
$faq-overview-spacing: 20px;
$faq-overview-vertical-spacing: 20px;

.c-faq-overview__faqs-grid {
  @include make-row-spacing($faq-overview-spacing, $faq-overview-vertical-spacing);
}

.c-faq-overview__faq {
  @include make-col-spacing($faq-overview-spacing, $faq-overview-vertical-spacing);

  > * {
    height: 100%;
  }
}


// Detail view

// Back button
.c-faq-detail__back {
  margin-bottom: rem(32px);

  @include mappy-bp(sm) {
    margin-bottom: rem(48px);
  }
}

// Headline
.c-faq-detail__headline {
  margin-bottom: rem(16px);

  @include mappy-bp(sm) {
    margin-bottom: rem(24px);
  }
}


// Single FAQ entry

// Sections
.c-faq__section {
  overflow-x: hidden; // prevent overflow scroll from negative grid margins
  position: relative;
  padding-bottom: rem(32px);

  @include mappy-bp(sm) {
    padding-bottom: rem(48px);
  }
}

.c-faq__section--with-top-spacing,
[class*="c-faq__section--push-next"] + .c-faq__section:not(.c-faq__section--no-top-spacing) {
  padding-top: rem(32px);

  @include mappy-bp(sm) {
    padding-top: rem(48px);
  }
}

.c-faq__section--no-bottom-spacing {
  padding-bottom: 0;
}

//
// Headlines
// --------------------------------------------------

.c-headline {
  display: block;
  font-weight: 700;
  text-transform: uppercase;
}

.c-headline--h1 {
  @include typi('h1');
}

.c-headline--h1-script {
  @include typi('h1-script');
}

.c-headline--h2 {
  @include typi('h2');
}

.c-headline--h2-script {
  @include typi('h2-script');
}

.c-headline--h3 {
  @include typi('h3');
}

.c-headline--h4 {
  @include typi('h4');
}

.c-headline--h5 {
  @include typi('h5');
}

.c-headline--h6 {
  @include typi('h6');
}

.c-headline--meta {
  @include typi('headline-meta');
  letter-spacing: .1em;
}

.c-headline--sub-lg {
  @include typi('headline-sub-lg');
  //letter-spacing: .1em;
  font-weight: 400;
}

.c-headline--sub {
  @include typi('headline-sub');
  //letter-spacing: .1em;
}

.c-headline--alpha {
  @include typi('headline-alpha');
}

.c-headline--alpha-script {
  @include typi('headline-alpha-script');
  hyphens: auto; // high potential of containing very wide words
}

.c-headline--divider {
  &::after {
    content: '';
    display: block;
    background-color: $color-primary;
    height: rem(4px);
    width: rem(67px);
    margin-top: rem(16px);

    .c-richtext--centered & {
      margin-left: auto;
      margin-right: auto;
    }

    @include mappy-bp(sm) {
      margin-top: rem(24px);

      // also centered inside overlay-head on >= sm
      .c-overlay__head & {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.c-headline--ci {
  @include font-ci;
  font-weight: 400;
  text-transform: none;
}

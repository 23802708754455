//
// Hero
// --------------------------------------------------

.c-hero {
  position: relative;
  background-color: $color-inverted;
  box-sizing: border-box; // re-reset box-sizing inside slider
}

.c-hero--slide {
  height: 100%;
}

// Image
.c-hero__img-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0; // < content

  height: 100%;
  width: 100%;
  overflow: hidden;
}

.c-hero__img-parallax {
  height: 100%;
  width: 100%;
}

.c-hero__img-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10; // > image
    background-color: rgba(0, 0, 0, .5);
  }

  picture {
    top: 0; // mandatory for lazysizes' object-fit-poylfill to work correctly
    width: 100%;
    height: 100%;
  }
}

.c-hero__img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
  transition: all 1.5s;

  &.lazyload,
  &.lazyloading,
  &.swiper-lazy:not(.swiper-lazy-loaded) {
    opacity: 0;
    transform: scale(1.05);
  }
}


// Content
.c-hero__content {
  position: relative;
  z-index: 10; // > background image
  text-align: center;
}

.c-hero__content-inner {
  position: relative;
  min-height: calc(100vh - #{rem($topbar-height)} - #{rem($bottombar-height)});
  padding-top: rem($section-spacing);
  padding-bottom: rem($section-spacing);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mappy-bp(sm) {
    padding-top: rem($section-spacing-sm);
    padding-bottom: rem($section-spacing-sm);
    min-height: calc(100vh - #{rem($topbar-height-sm)});
  }

  @include mappy-bp(lg) {
    padding-top: rem($section-spacing-lg);
    padding-bottom: rem($section-spacing-lg);
  }
}

.c-hero__head,
.c-hero__intro {
  text-shadow: $teaser-text-shadow;
}


// Intro animation
$heroAnimDurationDefault: 1.5s;
$heroAnimDurationExtended: 2s;
$heroAnimHeadlineDelay: .5s;
$heroAnimFollowDelayDefault: .75s;
$heroAnimFollowDelayExtended: 2s;

@mixin heroIntroAnimation($defaultDelay, $extendedDelay) {
  opacity: 0;
  transform: scale(.975);
  transition-property: opacity, transform;
  transition-duration: $heroAnimDurationDefault;
  transition-delay: $defaultDelay;
  transition-timing-function: ease;

  .c-hero--extend-intro & {
    transition-duration: $heroAnimDurationExtended;
    transition-delay: $extendedDelay;
  }

  .has-loaded-fonts-sans &,
  .has-failed-fonts-sans & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.c-hero__headline {
  @include heroIntroAnimation($heroAnimHeadlineDelay, $heroAnimHeadlineDelay);

  strong,
  span {
    display: inline-block;
  }

  .c-richtext & strong {
    font-weight: inherit;
  }
}

.c-hero__headline-meta {
  @include heroIntroAnimation($heroAnimFollowDelayDefault, $heroAnimFollowDelayExtended);

  strong,
  span {
    display: inline-block;
  }
}

.c-hero__subline {
  @include heroIntroAnimation($heroAnimFollowDelayDefault + .05s, $heroAnimFollowDelayExtended + .05s);

  strong,
  span {
    display: inline-block;
  }
}

.c-hero__intro {
  @include heroIntroAnimation($heroAnimFollowDelayDefault + .1s, $heroAnimFollowDelayExtended + .1s);
  max-width: map-get($container-max-widths, sm);
  margin-top: rem(16px);

  .c-hero__head--push-intro + & {
    margin-top: rem(32px);
  }
}

.c-hero__button {
  @include heroIntroAnimation($heroAnimFollowDelayDefault + .15s, $heroAnimFollowDelayExtended + .15s);

  margin-top: rem(24px);

  @include mappy-bp(lg) {
    margin-top: rem(32px);
  }
}


// Indicator
@keyframes heroIndicatorBounceAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(rem(-15px));
  }
}

.c-hero__indicator {
  @include heroIntroAnimation($heroAnimFollowDelayDefault + .2s, $heroAnimFollowDelayExtended + .2s);

  position: absolute;
  left: 0;
  right: 0;
  bottom: rem(16px);

  display: flex;
  justify-content: center;
  filter: drop-shadow($teaser-text-shadow);

  @include mappy-bp(h 800px) {
    bottom: rem(24px);
  }

  a {
    display: block;
    height: rem(30px * (266 / 438)); // angle dimensions
    width: rem(30px);

    &,
    &:hover,
    &:focus {
      color: inherit;
    }
  }

  svg {
    height: 100%;
    width: 100%;
    animation: heroIndicatorBounceAnimation .6s infinite alternate ease-in-out;
  }
}

//
// Topbar
// --------------------------------------------------

$topbar-logo-height: .44;
$topbar-logo-height-sm: .55;

.c-topbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: index($z-index, topbar);
  background-color: $color-primary;
  color: $color-text;
  will-change: transform;

  height: rem($topbar-height);

  @include mappy-bp(sm) {
    height: rem($topbar-height-sm);
  }
}

// Headroom modifiers
.c-topbar--headroom-initialized {
  transition: all $transition-time-medium;
}

body:not(.is-overlay-menu-open) .c-topbar--unpinned {
  transform: translateY(-100%);
}

.c-topbar__main {
  height: 100%;
}

.c-topbar__container {
  max-width: none;
  height: 100%;

  @include mappy-bp(sm) {
    padding-right: rem(24px);
    padding-left: rem(24px);
  }
}

.c-topbar__inner {
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-topbar__left {
  flex-shrink: 0;
  display: flex;
}

// Logo
.c-topbar__logo {
  display: block;
  transition: opacity $overlay-transition-duration;

  // the layout is ambiguous here => leave it in, just in case
  // body.is-overlay-menu-open & {
  //   opacity: 0;
  //   pointer-events: none;
  // }
}

.c-topbar__logo-shape {
  height: rem($topbar-height * $topbar-logo-height);
  width: rem($logo-shape-ratio * $topbar-height * $topbar-logo-height);

  @include mappy-bp(sm) {
    display: none;
  }
}

.c-topbar__logo-full {
  @include mappy-bp(sm) {
    height: rem($topbar-height-sm * $topbar-logo-height-sm);
    width: rem($logo-ratio * $topbar-height-sm * $topbar-logo-height-sm);
  }

  @include mappy-bp(max sm) {
    display: none;
  }
}


.c-topbar__right {
  height: 100%;
  flex-shrink: 0;
  margin-left: rem(24px);
  display: flex;
  align-items: center;
}

.c-topbar__meta {
  flex-shrink: 0;

  @include mappy-bp(max sm) {
    display: none;
  }
}

.c-topbar__contact {
  font-size: rem(14px);
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;

  a {
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;
    }
  }
}

.c-topbar__menu-toggler {
  flex-shrink: 0;
  display: block;

  &,
  &:hover,
  &:focus {
    color: inherit;
  }

  .c-topbar__meta + & {
    margin-left: rem(18px);
  }
}

.c-topbar__burger {
  flex-shrink: 0;
  position: relative;
  width: rem($topbar-btn-size);
  height: rem($topbar-btn-size);
  background-color: #fff;
  color: $color-inverted;

  display: flex;
  align-items: center;
  justify-content: center;

  $topbar-burger-svg-size: 49%;

  svg {
    position: absolute;
    top: (100% - $topbar-burger-svg-size) / 2;
    left: (100% - $topbar-burger-svg-size) / 2;
    height: $topbar-burger-svg-size;
    width: $topbar-burger-svg-size;

    transition: all $transition-time-default;
    transform: translate3d(0, 0, 0);

    // burger
    &:first-of-type {
      .is-overlay-menu-open & {
        transform: scale(.75);
        opacity: 0;
      }
    }

    // close
    &:last-of-type {
      transform: scale(.75);
      opacity: 0;

      .is-overlay-menu-open & {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

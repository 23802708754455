//
// Media object
// --------------------------------------------------

.c-media {
  position: relative;
}

.c-media--theme-gray {
  background-color: $color-gray-3;
}

.c-media--theme-white {
  background-color: #fff;
  color: $color-inverted;
}

.c-media__inner {
  position: relative;
  overflow: hidden;

  @include mappy-bp(lg) {
    display: flex;
    align-items: center;
    min-height: 100vh;
  }

  .c-media--reverse & {
    @include mappy-bp(lg) {
      flex-direction: row-reverse;
    }
  }
}

// Media box
.c-media__media-box {
  height: rem(450px);
  overflow: hidden;

  @include mappy-bp(lg) {
    width: 50%;
    flex-shrink: 0;
    height: auto;
    max-height: 100vh;
    align-self: stretch;
  }
}

.c-media__media-reveal {
  height: 100%;
  width: 100%;
}

// Image
.c-media__img-wrapper {
  display: block;
  width: 100%;
  height: 100%;

  picture {
    top: 0; // mandatory for lazysizes' object-fit-poylfill to work correctly
    width: 100%;
    height: 100%;
  }
}

.c-media__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all $transition-time-long;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}

.c-media__img--contain {
  object-fit: contain;
}

// Content
.c-media__content {
  position: relative;
  z-index: 10; // > image

  padding-top: rem(48px);
  padding-bottom: rem(48px);

  @include mappy-bp(sm) {
    padding-top: rem(64px);
    padding-bottom: rem(64px);
  }

  @include mappy-bp(lg) {
    padding-top: rem(96px);
    padding-bottom: rem(96px);

    flex-shrink: 0;
    width: 50%;
  }
}

.c-media__container {
  @include mappy-bp(lg) {
    padding-left: rem(48px);
    padding-right: rem(48px);
    margin-left: 0;

    .c-media--reverse & {
      margin-left: auto;
      margin-right: 0;
    }
  }

  @include mappy-bp(xl) {
    padding-left: rem(80px);
    padding-right: rem(80px);
  }
}

// Indicator
.c-media__indicator {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;

  &::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    margin-left: auto;
    margin-right: auto;

    border-style: solid;
    border-width: rem(35px) rem(35px) 0 rem(35px);
    border-color: $color-inverted transparent transparent transparent;

    .c-media--theme-gray & {
      border-top-color: $color-gray-3;
    }

    .c-media--theme-white & {
      border-top-color: #fff;
    }
  }
}

.c-media__indicator--side {
  @include mappy-bp(lg) {
    left: 50%;
    right: 0;

    .c-media--reverse & {
      left: 0;
      right: 50%;
    }
  }
}

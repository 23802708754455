//
// Typography settings
// --------------------------------------------------

// Font Families
$font-family-sans-fallback: 'Arial', sans-serif;
$font-family-sans: 'Barlow', $font-family-sans-fallback;
$font-family-ci-fallback: 'Brush Script MT', sans-serif;
$font-family-ci: 'Playlist-Script', $font-family-ci-fallback;

// Tell Typi to use mappy-breakpoints
$typi-breakpoint: mappy-bp;

// Font Sizing
/* stylelint-disable value-list-max-empty-lines */
$typi: (
  // Base for <html>, will be inherited
  base: (
    null: (16px, 1.5)
  ),

  paragraph: (
    null: (18px, 1.5),
  ),

  paragraph-sm: (
    null: (16px, 1.5),
  ),

  paragraph-lg: (
    null: (18px, 1.5),
    lg: (20px)
  ),

  paragraph-xl: (
    null: (18px, 1.5),
    lg: (24px)
  ),

  headline-alpha: (
    null: (36px, 1.2),
    sm: (52px),
    lg: (65px)
  ),

  headline-alpha-script: (
    null: (56px, 1.2),
    sm: (72px),
    lg: (81px)
  ),

  h1: (
    null: (32px, 1.2),
    sm: (48px),
    lg: (60px)
  ),

  h1-script: (
    null: (38px, 1.2),
    sm: (72px),
  ),

  h2: (
    null: (32px, 1.2),
    sm: (42px),
  ),

  h2-script: (
    null: (38px, 1.2),
    sm: (57px),
  ),

  h3: (
    null: (32px, 1.2),
  ),

  h4: (
    null: (28px, 1.2),
  ),

  h5: (
    null: (20px, 1.2),
  ),

  h6: (
    null: (18px, 1.2),
  ),

  headline-sub: (
    null: (18px, 1.2),
  ),

  headline-sub-lg: (
    null: (24px, 1.2),
    sm: (32px),
  ),

  headline-meta: (
    null: (16px, 1.2),
  ),

  button: (
    null: (16px, 1.2),
  ),

  form-element: (
    // null: (16px, 1.2),
    null: (20px, 1.2),
  ),

  hashtag: (
    null: (20px, 1),
  ),

  menu: (
    null: (18px, 1.2),
    sm: (20px),
  ),

  menu-level2: (
    null: (16px, 1.2),
    sm: (17px),
  ),

  footer: (
    null: (14px, 1.2),
  ),

  bottombar: (
    null: (18px, 1),
  ),

  cookieconsent: (
    null: (14px, 1.5),
  ),
);
/* stylelint-enable */

//
// Buttons
// --------------------------------------------------

$button-border-width: 2px;
$button-padding-x: 24px;
$button-icon-size: 10px;
$button-icon-spacing-x: 16px;
$button-icon-spacing-y: 6px;

.c-button {
  @include button-reset;
  cursor: pointer;

  @include typi('button');
  letter-spacing: .1em;
  font-weight: 700;
  text-transform: uppercase;
  transition: all $transition-time-medium;

  position: relative; // for background
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  min-width: rem(160px);
  padding-left: rem($button-padding-x);
  padding-right: rem($button-padding-x);

  color: #fff;
  border: rem($button-border-width) solid #fff;

  overflow: hidden;

  // Hover background
  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    background-color: #fff;
    transition: all $transition-time-medium;
    opacity: 0;
    transform-origin: left top;
    transform: translateY(100%) scale(1.2) rotate(15deg);
  }

  &:hover,
  &:focus {
    color: $color-inverted;

    &::before {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

.c-button__icon {
  z-index: 10; // > background
  height: rem($button-icon-size);
  width: rem($button-icon-size);
  margin-top: rem($button-icon-spacing-y);
  margin-bottom: rem($button-icon-spacing-y);

  // aspect ratio can be provided in HTML
  @supports (--css: variables) {
    --aspect-ratio: 1; // fallback = square

    width: calc(#{rem($button-icon-size)} * var(--aspect-ratio));
  }
}

// Primary inverted
.c-button--primary-inverted {
  color: $color-inverted;
  border-color: $color-inverted;

  &::before {
    background-color: $color-inverted;
  }

  &:hover,
  &:focus {
    color: #fff;
  }
}

// Secondary
.c-button--secondary {
  color: $color-inverted;
  background-color: #fff;

  &::before {
    background-color: $color-primary;
  }

  &:hover,
  &:focus {
    border-color: $color-primary;
  }
}

// Tertiary
.c-button--tertiary {
  color: $color-text-light;
  border-color: $color-text-light;

  &:hover,
  &:focus {
    border-color: #fff;
  }
}

// Small
.c-button--sm {
  font-weight: 400;
  letter-spacing: normal;
}

// Block
.c-button--block {
  display: flex;
  min-width: 0;
}

.c-button--block-xs {
  @include mappy-bp(max sm) {
    display: flex;
    min-width: 0;
  }
}

// Readonly
.c-button--readonly {
  pointer-events: none;
}

// Icon only on XS
.c-button--icon-only-xs {
  @include mappy-bp(max sm) {
    min-width: 0;
    padding-left: 0;
    padding-right: 0;

    .c-button__icon,
    .c-button__text + .c-button__icon {
      margin-left: rem($button-icon-spacing-x);
      margin-right: rem($button-icon-spacing-x);
    }

    .c-button__text {
      display: none;
    }
  }
}

// Text
.c-button__text {
  z-index: 10; // > background
  margin-top: rem(16px);
  margin-bottom: rem(16px);
}

// Inner spacing
.c-button__text + .c-button__icon,
.c-button__icon + .c-button__text {
  margin-left: rem(10px);
}


// Button group
$button-group-spacing: 10px;
$button-group-spacing-sm: 12px;
$button-group-spacing-lg: 16px;

.c-button-group {
  overflow: hidden;

  .c-button {
    margin-top: rem($button-group-spacing);
    margin-left: rem($button-group-spacing);
    margin-right: rem($button-group-spacing);

    @include mappy-bp(sm) {
      margin-top: rem($button-group-spacing-sm);
      margin-left: rem($button-group-spacing-sm);
      margin-right: rem($button-group-spacing-sm);
    }

    @include mappy-bp(lg) {
      margin-top: rem($button-group-spacing-lg);
      margin-left: rem($button-group-spacing-lg);
      margin-right: rem($button-group-spacing-lg);
    }
  }
}

.c-button-group--stretch {
  .c-button {
    flex-grow: 1;
  }
}

.c-button-group__inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: rem(-$button-group-spacing);
  margin-left: rem(-$button-group-spacing);
  margin-right: rem(-$button-group-spacing);

  @include mappy-bp(sm) {
    margin-top: rem(-$button-group-spacing-sm);
    margin-left: rem(-$button-group-spacing-sm);
    margin-right: rem(-$button-group-spacing-sm);
  }

  @include mappy-bp(lg) {
    margin-top: rem(-$button-group-spacing-lg);
    margin-left: rem(-$button-group-spacing-lg);
    margin-right: rem(-$button-group-spacing-lg);
  }

  .c-button-group--centered & {
    justify-content: center;
    align-items: center;
  }

  .c-button-group--column-xs & {
    @include mappy-bp(max sm) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

// Button as a block
.c-button-block {
  text-align: center;
}


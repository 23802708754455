//
// Icon teaser
// --------------------------------------------------

.c-teaser-icon {
  display: block;
  background-color: #fff;
  padding: rem(16px);
  color: $color-text-inverted;
  text-align: center;
  transition: all $transition-time-default;
  transform-origin: bottom center;
  will-change: transform;

  &:hover,
  &:focus {
    transform: translateY(rem(-2px)) scale(1.025);
    box-shadow: 0 rem(2px) rem(30px) rgba(0, 0, 0, .125);
  }
}

.c-teaser-icon__icon {
  height: rem(64px);
  width: rem(64px);
  margin-left: auto;
  margin-right: auto;
}

.c-teaser-icon__headline {
  margin-bottom: rem(12px);
  text-transform: uppercase;
  hyphens: auto;
}

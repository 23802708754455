//
// Team member (small variant)
// --------------------------------------------------

.c-team-member-small {
  background-color: $color-gray-3;

  @include mappy-bp(sm) {
    display: flex;
    justify-content: center;
  }
}

// Image
.c-team-member-small__media-box {
  @include mappy-bp(sm) {
    width: 50%;
    flex-shrink: 0;
  }
}

// 100% AND ar
.c-team-member-small__img-wrapper {
  position: relative;
  height: 100%;
  padding-bottom: 90%;
  overflow: hidden;
}

.c-team-member-small__img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: opacity $transition-time-default;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}

// Content
.c-team-member-small__content {
  padding: rem(40px) rem(20px);

  @include mappy-bp(sm) {
    width: 50%;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    > * {
      width: 100%;
    }
  }

  .c-richtext {
    a {
      color: inherit;

      &:hover,
      &:focus {
        color: $color-link;
      }
    }

    .c-team-member-small__name {
      font-weight: 400;
    }

    .c-team-member-small__position {
      text-transform: uppercase;
      margin-bottom: .5em;
      display: inline-block;
    }
  }
}
